export const columns = {
  fieldpicture: {
    picture: {
      label: 'Picture',
      sortable: false,
    },
    content_type: {
      label: 'Target',
      sortable: true,
    },
    field_name: {
      label: 'Criteria',
      sortable: true,
    },
    field_value: {
      label: 'Value',
      sortable: true,
    },
  },
  gallery: {
    portrait: {
      label: 'Portrait',
      sortable: false,
    },
    name: {
      label: 'Name',
      sortable: true,
    },
    featured: {
      label: 'Feat.',
      sortable: true,
    },
    artist: {
      label: 'Artists',
      sortable: true,
    },
    tap: {
      label: 'visibility',
      sortable: true,
    },
    causes: {
      label: 'Causes',
      sortable: false,
    },
    status: {
      label: 'Status',
      sortable: true,
    },
  },
  artist: {
    portrait: {
      label: '',
      sortable: false,
    },
    name: {
      label: 'Name',
      sortable: true,
    },
    featured: {
      label: 'Feat.',
      sortable: true,
    },
    gallery_set: {
      label: 'Galleries',
      sortable: false,
    },
    artwork_set: {
      label: 'Artworks',
      sortable: false,
    },
    cause_set: {
      label: 'Causes',
      sortable: false,
    },
    status: {
      label: 'Status',
      sortable: false,
    },
  },
  artwork: {
    portrait: {
      label: '',
      sortable: false,
    },
    title: {
      label: 'Title',
      sortable: true,
    },
    featured: {
      label: 'Featured',
      sortable: true,
    },
    artist: {
      label: 'Artists',
      sortable: true,
    },
    gallery: {
      label: 'Gallery',
      sortable: true,
    },
    causes: {
      label: 'Causes',
      sortable: false,
    },
    status: {
      label: 'Status',
      sortable: true,
    },
  },
  cause: {
    picture: {
      label: '',
      sortable: false,
    },
    name: {
      label: 'Name',
      sortable: true,
    },
    projects: {
      label: 'Projects',
      sortable: true,
    },
    artist: {
      label: 'Artists',
      sortable: true,
    },
    gallery: {
      label: 'Galleries',
      sortable: true,
    },
    status: {
      label: 'Status',
      sortable: true,
    },
  },
  project: {
    picture: {
      label: '',
      sortable: false,
    },
    name: {
      label: 'Name',
      sortable: true,
    },
    featured: {
      label: 'Feat.',
      sortable: true,
    },
    causes: {
      label: 'Causes',
      sortable: false,
    },
    artist: {
      label: 'Artists',
      sortable: true,
    },
    galleries: {
      label: 'Galleries',
      sortable: true,
    },
    status: {
      label: 'Status',
      sortable: true,
    },
  },
  event: {
    portrait: {
      label: '',
      sortable: false,
    },
    name: {
      label: 'Name',
      sortable: true,
    },
    artists: {
      label: 'Artists',
      sortable: false,
    },
    galleries: {
      label: 'Galleries',
      sortable: false,
    },
    institutions: {
      label: 'Institutions',
      sortable: false,
    },
    // artworks: {
    //   label: 'Artworks',
    //   sortable: true,
    // },
  },
  institution: {
    portrait: {
      label: '',
      sortable: false,
    },
    name: {
      label: 'Name',
      sortable: true,
    },
    artists: {
      label: 'Artists',
      sortable: false,
    },
    // galleries: {
    //   label: 'Galleries',
    //   sortable: false,
    // },
    // causes: {
    //   label: 'Causes',
    //   sortable: false,
    // },
  },
  tag: {
    name: {
      label: 'Name',
      sortable: true,
    },
    category: {
      label: 'Category',
      sortable: true,
    },
    wikipedia: {
      label: 'Wikipedia',
      sortable: false,
    },
  },
  organisation: {
    name: {
      label: 'Name',
      sortable: true,
    },
    projects: {
      label: 'Projects',
      sortable: true,
    },
    country: {
      label: 'HQ Country',
      sortable: true,
    },
  },
  article: {
    title: {
      label: 'Title',
      sortable: true,
    },
    category: {
      label: 'Categories',
      sortable: false,
    },
    artists: {
      label: 'Artists',
      sortable: false,
    },
    galleries: {
      label: 'Galleries',
      sortable: false,
    },
  },
  transaction: {
    status: {
      label: 'Status',
      sortable: false,
    },
    total: {
      label: 'Total price',
      sortable: true,
    },
    description: {
      label: 'Artwork',
      sortable: false,
    },
    buyer: {
      label: 'Buyer',
      sortable: false,
    },
    date: {
      label: 'Transaction date',
      sortable: true,
    },
    paymentLink: {
      label: 'Payment',
      sortable: false
    }
    // artwork: {
    //   label: 'Artwork',
    //   sortable: false,
    // },
    // project: {
    //   label: 'Pledge to',
    //   sortable: false,
    // },
  },  
}
