<template>
  <section v-if="item">
    <BaseInputContainer
      v-model="item.world"
      :errors="errors.world"
      :required="fields.world.required"
      field-type="integer"
      label="World visibility"
      @update="onFieldUpdate"
      @input="onFieldInput"
    />
    <BaseInputContainer
      v-model="item.national"
      :errors="errors.national"
      :required="fields.national.required"
      field-type="integer"
      label="National visibility"
      @update="onFieldUpdate"
      @input="onFieldInput"
    />
    <BaseInputContainer
      v-model="item.media"
      :errors="errors.media"
      field-type="integer"
      :required="fields.media.required"
      label="Media visibility"
      @update="onFieldUpdate"
      @input="onFieldInput"
    />
    <BaseInputContainer
      v-model="item.instagram"
      :errors="errors.instagram"
      :required="fields.instagram.required"
      field-type="integer"
      label="Number of followers on Instagram"
      @update="onFieldUpdate"
      @input="onFieldInput"
    />
    <!-- <BaseInputContainer
      v-model="item.nb_instagram_followers"
      :errors="errors.nb_instagram_followers"
      :required="fields.nb_instagram_followers.required"
      field-type="integer"
      label="Number of instagram followers"
      @update="onFieldUpdate"
      @input="onFieldInput"
    /> -->
    <BaseInputContainer
      v-model="item.tap"
      :errors="errors.tap"
      :required="fields.tap.required"
      field-type="integer"
      label="Tap visibility"
      @update="onFieldUpdate"
      @input="onFieldInput"
    />
  </section>
</template>

<script>
import {modelForms} from '@/mixins/modelForms.js'

export default {
  name: "VisibilityForm",
  mixins: [modelForms],
  computed: {
    fields: function(){
      return this.$parent.availableFields.visibility.children
    },
  },
}

</script>

<style src="../assets/css/forms.css" scoped></style>
<style scoped>

</style>
